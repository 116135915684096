<template>
  <div id="p1s1">
    <!-- Loading businesses view -->
    <div v-if="DV_noBusinessFound || DV_isCheckingAuthentication || DV_loadingBusinesses" class="w-6/12 mx-auto mt-40 border border-gray-400 rounded p-12">
      Loading account information...
    </div>

    <div v-else class="grid grid-cols-12 md:gap-4 p-3">
      <!-- Queue section (left) -->
      <div class="max-height col-span-12 md:col-span-3">
        <p1s1c1CustomersInQueue 
          @set-mobile-view="changeMobileView" 
          @event-open-conversation="openConversationEvent"
          @event-open-profile-modal="openProfileModal"
          @event-open-chat-log-modal="openChatLogModal"
          @event-open-consumer-feedback-log-modal="openConsumerFeedbackLogModal"
        />
      </div>

      <!-- Active conversations (center) -->
      <div v-show="!DV_newConversationView" class="max-height block md:col-span-6">
        <c1ChatModule
          :P_activeConversationUid="DV_activeConsumerConversationUuid"
          @close-chat="DV_activeConsumerConversationUuid = null"
        /> 
      </div>

      <!-- Conversations list (right) -->
      <div :class="[DV_newConversationView ? 'md:col-span-9' : 'md:col-span-3']" class="max-height md:grid hidden">
        <p1s1c2Conversations 
          @event-open-conversation="openConversationEvent"
          @show-new-conversation="showNewConversation"
          @event-open-profile-modal="openProfileModal"
          @event-open-chat-log-modal="openChatLogModal"
          @event-open-consumer-feedback-log-modal="openConsumerFeedbackLogModal"
          @event-open-consumer-business-modal="openEditNewConsumerBusinessModal"
          :P_activeConversationUid="DV_activeConsumerConversationUuid"
        />
      </div>

      <!-- Conversations list mobile view -->
      <div v-if="DV_showConversation" class="md:hidden grid col-span-10">
        <p1s1c2Conversations :active-conversation-index="DV_activeConsumerConversationUuid" />
      </div>
    </div>

    <mod2CustomerProfile
      ref="customerProfileModal"
      :P_consumerUid="DV_modalConsumerUid"
      :P_viewType="'editView'">
    </mod2CustomerProfile>
    
    <mod3CustomerFeedback
      ref="consumerFeedbackLogModal"
      :P_consumerUid="DV_modalConsumerUid">
    </mod3CustomerFeedback>

    <mod5CustomerHistory
      ref="customerChatHistoryModal"
      :P_consumerUid="DV_modalConsumerUid"
      :P_readOnly="true">
    </mod5CustomerHistory>
    
    <mod7EditNewBusinessForm
      ref="editNewConsumerBusinessModal"
      :P_businessUid="DV_consumerBusinessModalUid"
      :P_viewType="'editBusiness'">
    </mod7EditNewBusinessForm>
  </div>
</template>

<script>
import p1s1c1CustomersInQueue from "./p1s1c1CustomersInQueue.vue";
import p1s1c2Conversations from "./p1s1c2Conversations.vue";
import c1ChatModule from "../c1/c1ChatModule.vue";
import AWN from "awesome-notifications";
import { fs73Writer } from "../../includes/firestore_writers/fs73_writer.ts"
import { fs6Writer } from "../../includes/firestore_writers/fs6_writer.ts"
import mod2CustomerProfile from "../shared/mod2CustomerProfile.vue"
import mod5CustomerHistory from "../shared/mod5CustomerHistory.vue"
import mod3CustomerFeedback from "../shared/mod3CustomerFeedback.vue"
import mod7EditNewBusinessForm from "../shared/mod7EditNewBusinessForm.vue"
import m2MiscDataFetchers from "../../mixins/m2MiscDataFetchers"


export default {
  name: "p1s1MyConversations",

  mixins: [m2MiscDataFetchers],

  components: {
    p1s1c1CustomersInQueue,
    p1s1c2Conversations,
    c1ChatModule,
    mod2CustomerProfile,
    mod3CustomerFeedback,
    mod5CustomerHistory,
    mod7EditNewBusinessForm
  },

  data() {
    return {
      DV_showConversation: false,
      DV_activeConsumerConversationUuid: null,
      DV_modalConsumerUid: null,
      DV_modalConversationUid: null,
      DV_loadingBusinesses: true,
      DV_noBusinessFound: false,
      DV_isCheckingAuthentication: true,
      DV_newConversationView: false,
      DV_recyclerLocationUid: window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
      DV_businessUid: window.G_bi2_data.IV_active_employee_info.business_uid,
      DV_yardSideChatInvites: {},
      DV_yardSideChatInviteShowing: null,
      DV_activeModalToShow: 0,
      DV_consumerBusinessModalUid: "",
      DV_modalIds: {
        consumer_profile: 1,
        consumer_chat_logs: 2,
        consumer_feedback_log: 3,
      },
    };
  },

  mounted() {
    this.authenticationCheck(0);
    window.document.title = "Synergy Auto Solutions - Sales Dashboard";
    this.DV_yardSideChatInvites = window.G_bi2_data.IV_yard_side_chat_invites.value;

    console.log(this.$route)

    // show info msg, if account info is not loaded after 5 seconds
    setTimeout(function() {
      if (this.DV_noBusinessFound || this.DV_isCheckingAuthentication || this.DV_loadingBusinesses) { 
        this.$awn.info("If you're unable to access an active account, try clearing your browser cache; this should allow you to log in again.");
      }
    }.bind(this), 5000)
  },

  methods: {
    logoutUser() {
      window.G_firebase_auth.signOut();
    },

    
    openEditNewConsumerBusinessModal(business_uid) {
      this.DV_consumerBusinessModalUid = business_uid;
      this.$refs.editNewConsumerBusinessModal.showSelf();
    },

    openProfileModal(consumer_uid) {
      this.DV_activeModalToShow = this.DV_modalIds.consumer_profile;
      this.DV_modalConsumerUid = consumer_uid;
      this.DV_modalConversationUid = null;
      this.$refs.customerProfileModal.showSelf();
    },

    openChatLogModal(consumer_uid) {
      this.DV_activeModalToShow = this.DV_modalIds.consumer_chat_logs;
      this.DV_modalConsumerUid = consumer_uid;
      this.DV_modalConversationUid = null;
      this.$refs.customerChatHistoryModal.showSelf();
    },

    openConsumerFeedbackLogModal(consumer_uid) {
      this.DV_activeModalToShow = this.DV_modalIds.consumer_feedback_log;
      this.DV_modalConsumerUid = consumer_uid;
      this.DV_modalConversationUid = null;
      this.$refs.consumerFeedbackLogModal.showSelf();
    },

    authenticationCheck(retry_count) {
       const CI = this;


        if (retry_count >= 40 && window.location.pathname !== "/login" && window.location.pathname !== "/") {
          location.assign(window.location.origin + `/login`);
        return;
      }

      if (window.G_firebase_auth.IV_is_user_logged_in !== true) {
        setTimeout( function() {CI.authenticationCheck(++retry_count)}, 100);
        return
      };

      this.DV_isCheckingAuthentication = false;
      this.dataLoadedCheck(0,200);
    },

    dataLoadedCheck(retry_count=1, max_retry_count = 40) {

      const CI = this;
      if (retry_count >= max_retry_count &&
        window.location.pathname !== "/login" && 
        window.location.pathname !== "/" && 
        window.location.pathname !== "/account-setup" &&
        window.location.pathname !== "/axe-account-setup" &&
        window.location.pathname !== "/slimcd-account-setup"
     ) {
      this.DV_noBusinessFound = true;
      location.assign(window.location.origin + `/account-setup`);
      this.DV_loadingBusinesses = false;
      return;
    }

    let account_has_data = false;
    if (window.G_bi2_data.IV_active_employee_info.business_uid !== ""
    || window.G_bi2_data.IV_active_employee_info.recycler_location_uid !== "") {
      account_has_data = true;
      this.DV_noBusinessFound = false;
    }
    if(Object.keys(window.G_bi2_data.IV_loggedin_user_active_invites.value).length > 0) {
      account_has_data = true;
    }

    if(account_has_data === false) {
      setTimeout( function() {CI.dataLoadedCheck(++retry_count,max_retry_count)}, 100);
        return;
    }

      this.DV_loadingBusinesses = false;
      this.startConversationQueueListener();

    },

    startConversationQueueListener(retry_count=1) {
      const CI = this;
      if (retry_count >= 10) { return; }

      if (!window.G_bi2_data.IV_active_employee_info.business_uid || !window.G_bi2_data.IV_active_employee_info.recycler_location_uid) {
        setTimeout( function() {CI.startConversationQueueListener(++retry_count)}, 1000);
        return
      };

      window.G_bi2_data.startConversationQueueListener(
        window.G_bi2_data,
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid
      )

      window.G_bi2_data.startConsumersActivityStatusListener(
        window.G_bi2_data,
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        window.G_bi2_data.IV_active_employee_info.employee_uid
      )
    },

    changeMobileView(view) {
      this.DV_showConversation = view != "queue";
    },

    openConversationEvent(consumer_conversation_uuid, retry_count=0) {
      const CI = this;

      if (retry_count > 20) {return; }

      // Wait for some time to make sure that the firestore gets update and the bi2 write to active conversations.
      if (consumer_conversation_uuid && !consumer_conversation_uuid in window.G_bi2_data.IV_consumer_conversations.value) {
        setTimeout(function() {CI.openConversationEvent(uid, ++retry_count)}, 100);
        return
      }

      this.DV_activeConsumerConversationUuid = consumer_conversation_uuid;
      this.DV_modalConsumerUid, this.DV_modalConversationUid = this.splitConsumerConversationUuid(this.DV_activeConsumerConversationUuid);
    },

    showNewConversation(command) {
      this.DV_newConversationView = command;
      this.DV_activeConsumerConversationUuid = null;
    },

    checkAndShowYardChatInvite() {
      const CI = this;

      let notifier = new AWN();

      for (let inviteUid in CI.DV_yardSideChatInvites) {
        var invite = CI.DV_yardSideChatInvites[inviteUid];

        // Already rejected / accepted? Skip
        if (invite.last_response && (invite.last_response == 1 || invite.last_response == 2)) {
          continue;
        }

        CI.DV_yardSideChatInviteShowing = invite;
        let inviteeName = "Your colleague";

        if (invite.inviting_employee_uid in window.G_bi2_data.IV_recycler_employees.value) {
          let employee = window.G_bi2_data.IV_recycler_employees.value[invite.inviting_employee_uid];
          inviteeName = `${employee.first_name} ${employee.last_name}`
        }

        notifier.confirm(
          // Label
          `<b>${inviteeName}</b> has invited you to join a conversation. Do You want to join?`,
          
          // On OK
          CI.acceptYardOnlyChatInvite,

          // On Cancel
          CI.declineYardOnlyChatInvite,

          // Other options
          {
            labels: {
              confirm: 'Conversation Invitation',
              confirmOk: "Join",
              confirmCancel: "Decline"
            }
          }
        );

        // Show one alert at a time.
        break;
      }
    },

    async acceptYardOnlyChatInvite() {
      const CI = this;
      
      // fs73_writer.ts updates the invite status
      const fs73Resp = await fs73Writer(
        "e",
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        window.G_bi2_data.IV_active_employee_info.employee_uid,
        CI.DV_yardSideChatInviteShowing.consumer_uid,
        CI.DV_yardSideChatInviteShowing.conversation_uid,
        {
          last_response: 2,
          response_timestamp: new Date()
        }
      );

      if (fs73Resp.success == false) {
        this.$awn.alert("There was an error while accepting invite, please reload page and try again.")
        return;
      }

      // fs6_writer.ts sets adds the invite employee UID to fs6 > recycler_only_chat_employee_uid_list 
      // fs6_writer.ts removes the invited employee UID from fs6 > invited_employee_only_chat_uid_list
      const fs6Resp = await fs6Writer(
        "e",
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        CI.DV_yardSideChatInviteShowing.consumer_uid,
        CI.DV_yardSideChatInviteShowing.conversation_uid,
        {
          recycler_only_chat_employee_uid_list: [window.G_bi2_data.IV_active_employee_info.employee_uid],
          invited_employee_only_chat_uid_list: [`deleted-${window.G_bi2_data.IV_active_employee_info.employee_uid}`]
        }
      )

      if (fs6Resp.success == true) {
        // Check for more invites
        CI.checkAndShowYardChatInvite();

        setTimeout(() => {
          this.DV_activeConsumerConversationUuid = `${CI.DV_yardSideChatInviteShowing.consumer_uid}-${CI.DV_yardSideChatInviteShowing.conversation_uid}`
          this.DV_modalConsumerUid, this.DV_modalConversationUid = this.splitConsumerConversationUuid(this.DV_activeConsumerConversationUuid);
        }, 50)

      } else {
        this.$awn.alert("There was an error accepting the invite, please reload page and try again.");
      }
    },

    async declineYardOnlyChatInvite() {
      const CI = this;

      // fs73_writer.ts updates the invite status
      const fs73Resp = await fs73Writer(
        "e",
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        window.G_bi2_data.IV_active_employee_info.employee_uid,
        CI.DV_yardSideChatInviteShowing.consumer_uid,
        CI.DV_yardSideChatInviteShowing.conversation_uid,
        {
          last_response: 1,
          response_timestamp: new Date()
        }
      );

      if (fs73Resp.success == true) {
        // Check for more invites
        CI.checkAndShowYardChatInvite();
      } else {
        this.$awn.alert("There was an error while declining the invite, please reload page and try again.")
      }
    }
  },

  watch: {
    DV_yardSideChatInvites: {
      handler() {
        this.checkAndShowYardChatInvite();
      },
      deep: true
    },
  }
};
</script>

<style>
@media (min-width: 480px) {
  .max-height {
    height: calc(100vh - 1.5rem);
    max-height: calc(100vh - 1.5rem);
    grid-template-rows: 100%;
  }
}
</style>
